import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.page.html',
  styleUrls: ['./error-modal.page.scss'],
})
export class ErrorModalPage implements OnInit, OnDestroy {

@Input() message: string;



  constructor(private modalService: ModalController, private navParams: NavParams) { }

  ngOnInit() {

  }

  close() {
    this.modalService.dismiss();
  }

  ngOnDestroy() {

  }

}
