import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { EventObject, ResourceService } from 'src/app/services/resources/resource.service';
import { DateTime } from 'ews-js-api-browser';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-meeting-timer',
  templateUrl: './meeting-timer.component.html',
  styleUrls: ['./meeting-timer.component.scss'],

})
export class MeetingTimerComponent implements OnInit, OnChanges, OnDestroy {
  public distance;
  public duration;
  public timer;
  public autoCancel;
  public settings;
  public progress;
  private subscription: Subscription;
  @Input('Start') start;
  @Input('End') end;
  @Input('Duration') mduration;
  @Input('MeetingType') event: EventObject;
  @Input('MeetingStatus') status;


  constructor(private resourceService: ResourceService) {
  }

  ngOnInit() {
   this.subscription = this.resourceService.resource.subscribe(res => {
      this.settings = res.settings;
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
    }
    // (changes.Meeting || changes.status || changes.End) &&
    if ( this.event != null ) {
      if (this.event.activeStatus === 2) {
        this.initProgress(this.end, this.mduration);
      } else if (this.event.activeStatus === 1) {
        this.initProgress(this.end, this.mduration);
      }

    }
  }

  initProgress(endTime, meetingDuration) {

    clearInterval(this.timer);
    const now = new Date().getTime();
    this.timer = setInterval(() => {

      const parsedStart = DateTime.Parse(this.start);
      const parsedEndTime = DateTime.Parse(endTime);
      const start = parsedStart.TotalMilliSeconds + (this.settings.cancelTime * 60000);
      const cancelTime = this.settings.cancelTime * 60000;
      if (this.event.activeStatus === 2) {
        this.startprogress(parsedEndTime.TotalMilliSeconds, meetingDuration);
      } else if (this.event.activeStatus === 1 && this.settings.autoCancel && now < start) {
        this.startprogress(start, cancelTime);
      }

    }, 1000);
  }


  public startprogress(time, meetingDuration) {
    // Get Now Time as Milliseconds
    const now = new Date().getTime();
    // Get Duration in Milliseconds
    const duration = time - now;
    // Timer Formatting
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);
    // Divide duration by meetingDuration (eg. 15Min)
    if (this.event.activeStatus === 2) {
      this.progress = duration / meetingDuration.totalMilliseconds;
    } else {
      this.progress = duration / meetingDuration;
    }
    // Assign to the FrontEnd
    this.distance = this.progress;
    // Assign Timer Clock
    let _hour;
    _hour = hours < 10 ? _hour = '0' + hours : _hour = hours;
    let _minutes;
    _minutes = minutes < 10 ? _minutes = '0' + minutes : _minutes = minutes;
    let _seconds;
    _seconds = seconds < 10 ? _seconds = '0' + seconds : _seconds = seconds;

    this.duration = _hour + ':' + _minutes  + ':' +  _seconds;

    if (duration < 1000) {
      this.duration = '00:00:00';
      if (this.settings.autoCancel && this.event.activeStatus === 1) {
        //this.resourceService.checkOutEvent(this.event.id).toPromise();
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
    this.subscription.unsubscribe();
  }
}
