
<ion-item>
  <ion-label>{{'LANGUAGESWITCHER.TITLE' | translate}}</ion-label>
  <ion-select cancelText="{{'BTN.CANCEL' | translate}}" okText="{{'BTN.OK' | translate}}"  mode="ios" interface="popover" [formControl]="lang">
    <ion-select-option *ngFor="let lng of languages" [value]="lng.value">{{lng.text}}</ion-select-option>
  </ion-select>
</ion-item>
      



