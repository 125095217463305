import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-no-licenses-modal',
  templateUrl: './no-licenses-modal.component.html',
  styleUrls: ['./no-licenses-modal.component.scss'],
})
export class NoLicensesModalComponent implements OnInit {

  public message: string;
  constructor(private navParams: NavParams) { }

  ngOnInit() {}

}
