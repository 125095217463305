import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  constructor() { }


  static isValidEmail(control: FormControl) {
    return new Promise(resolve => {
      if (control.touched || control.dirty) {
        // tslint:disable-next-line: max-line-length
        const regex = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        const res = regex.test(control.value);

        if (!res) {
          resolve({
            pattern: true
          });
        }
      }
      resolve(null);
    });

  }


  static isValidPassword(control: FormControl) {
    return new Promise(resolve => {
      if (control.touched || control.dirty) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$^+=!()@%&?_*-.;§[\]{}|<>~:`"/\€°])[A-Za-z\d#$^+=!()@%&?_*-.;§[\]{}|<>~:`"/\€°]{8,}$/;
        const res = regex.test(control.value);

        if (!res) {
          resolve({
            pattern: true
          });
        }
      }
      resolve(null);
    });
  }

  static isValidURL(control: FormControl | string) {
    const regex = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i');

    if (typeof (control) === 'object') {
      if ((control.touched || control.dirty) && control.value.trim() !== '') {
        const res = !!(regex.test(control.value));
        if (!res) {
          return ({
            pattern: true
          });
        }
      }
      return null;
    } else {
      const res = !!(regex.test(control));
      return res;
    }
  }


  static matchingPassword(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.matchingPassword) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ NoPassswordMatch: true });
      } else {

        matchingControl.setErrors(null);
      }
    };
  }
}

export class ValidationErrors {
  public static validMsg = {
    email: [
      { type: 'required', message: 'VALID.EMAIL.REQ' },
      { type: 'pattern', message: 'VALID.EMAIL.PATT' },
    ],
    serverurl: [
      { type: 'pattern', message: 'VALID.URL.PATT' }
    ],
    pw: [
      { type: 'required', message: 'VALID.PW.REQ' },
      { type: 'minlength', message: 'VALID.PW.MINL' },
      { type: 'pattern', message: 'VALID.PW.PATT' },

    ],
    pw_confirm: [
      { type: 'required', message: 'VALID.PW.REQ' },
      { type: 'NoPassswordMatch', message: 'VALID.PW.MATCHEDPW' }
    ],
    name: [
      { type: 'required', message: 'VALID.NAME.REQ' }
    ],
    text: [
      { type: 'required', message: 'VALID.TEXT.REQ' },
      { type: 'pattern', message: 'VALID.TEXT.URLFORMAT' }
    ],
    number: [
      { type: 'required', message: 'VALID.NUMB.REQ' },
      { type: 'min', message: 'VALID.NUMB.MIN' },
      { type: 'max', message: 'VALID.NUMB.MAX' }
    ],

  };
}
