import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, fromEvent } from 'rxjs';

export enum ConnStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkserviceService {

  public status: BehaviorSubject<ConnStatus> = new BehaviorSubject(ConnStatus.Offline);
  constructor(private network: Network, private platform: Platform) {
    this.platform.ready().then(() => {
      if (this.platform.is('hybrid') === true) {

        this.checkNetwork();
        const status =  this.network.type !== 'none' ? ConnStatus.Online : ConnStatus.Offline;
        this.status.next(status);
      } else {

        this.browserNetwork();
        const status =  this.network.type !== 'none' ? ConnStatus.Online : ConnStatus.Offline;
        this.status.next(status);
      }
    });
  }



  public checkNetwork() {



    this.network.onDisconnect().subscribe(() => {

      this.updateStatus(ConnStatus.Offline);
    });

    this.network.onConnect().subscribe(() => {

      this.updateStatus(ConnStatus.Online);
    });
  }

  updateStatus(status: ConnStatus) {
    this.status.next(status);
  }

  browserNetwork() {
    fromEvent(window, 'offline').subscribe(() => {
      this.updateStatus(ConnStatus.Offline);
    });

    fromEvent(window, 'online').subscribe(() => {
      this.updateStatus(ConnStatus.Online);
    });
  }
}
