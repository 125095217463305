import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DateTime, TimeSpan } from 'ews-js-api-browser';
import { WebsocketService } from '../api/websocket.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language-service/language.service';

import { formatDate } from '@angular/common';



@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  private currentDay = DateTime.Now.Today;
  public clock;
  public date;
  public resource: BehaviorSubject<ResourceObject> = new BehaviorSubject(undefined);
  public routput;
  public resourceSettings: BehaviorSubject<RoomBoardSettings> = new BehaviorSubject(undefined);
  public events = new BehaviorSubject<any[]>([]);
  public avatar = new BehaviorSubject<any>('./assets/img/logo_r-1080.png');


  constructor(
    private socket: WebsocketService,
    private translate: TranslateService,
    private language: LanguageService,

  ) {
    this.startClock();
  }


  startEventListener() {
    return this.socket.eventsCallback.pipe(switchMap(rooms => {
      console.log(rooms, this.resource.getValue().rooms)
      const a: ResourceObject = this.eventMapper(rooms, this.resource.getValue());
      console.log('return from eventsmapper :', a.rooms);
      this.resource.next(a);
      //this.events.next(a.rooms);
      return of(true);
    }));
  }

  startResourceListener() {
    return this.socket.resourceCallback.pipe(
      switchMap((resource) => {
        console.log(resource);
        this.checkResource(resource);
        if (resource.connectorId != null) {
          this.socket.removeConnectorCallback.next(false);
        }
        return of(true);
        //return this.checkPlace(resource).pipe();
      })
    );
  }


  checkResource(resource: ResourceObject) {
    if (resource.avatar != null) {
      this.avatarBlobber(resource.avatar);
    }
    this.language.setLanguage(resource.language);
    this.resourceSettings.next(resource.settings);
    console.log('check res', resource.rooms)
    this.resource.next(resource);

  }




  eventMapper(list1: any, list2: any) {

    list1.forEach((room: any) => {
      list2.rooms.forEach((r: any) => {
        if (r._id === room._id) {
          r.events = room.events;
          if (r.events.length > 0 && r.events[1] !== undefined) {
            r.events[0].nextSubject += ' um ' + formatDate(r.events[1].start, 'shortTime', this.language.selected.getValue());
          }
          return list2;
        }
      });

    });
    return list2;
  }

  checkPlace(resource: ResourceObject) {
    if (resource.connectorId == null || resource.connectorId == undefined) {
      return of(true);
    }
    if (resource.rooms.length == null || resource.rooms.length == undefined) {
      return of(false);
    } else {
      return of(true);
    }
  }


  // Helper

  startClock() {

    setInterval(() => {
      this.clock = new Date();
      this.date = new Date();
      //this.checkIfNextDay();
    }, 1000);
  }

  avatarBlobber(avatar) {
    const img = avatar;
    fetch(avatar).then(r =>
      r.blob().then(b => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(b);
        fileReader.onload = ev => {
          this.avatar.next(ev.target.result);
        };
      }));
  }





  /*checkMembers() {
    return this.activeEvent.pipe(
      switchMap((event: EventObject) => {
        if (event !== undefined) {
          const memberArr = [];
          event.members.forEach(members => {
            if (members.includes('@')) {
              members = this.translate.instant('LABEL.PARTICIPANT_EX');
            }
            memberArr.push(members);
          });
          event.members = memberArr;
          return of(true);
        } else {
          return of(true);
        }
      }));
  }*/


  // startEventListener(): Observable<any> {
  //   return this.socket.eventsCallback.pipe(
  //     switchMap(eventsList => {
  //       console.log(eventsList);
  //       return this.checkEvents(eventsList).pipe(
  //         switchMap(events => {
  //           console.log(events);
  //           this.events.next(events);
  //           const aMeeting = events.find(e => e.activeStatus === 2 || e.activeStatus === 1 || e.activeStatus === 0);
  //           this.activeEvent.next(aMeeting);
  //           return this.checkMembers().pipe();
  //         })
  //       );
  //     })
  //   );
  // }


    /*checkEvents(eventslist) {
    const newEventList = [];
    eventslist.forEach((event) => {
      const parsedTime = DateTime.Parse(event.start);
      if (DateTime.Compare(parsedTime.Today, this.currentDay) !== 0) {
        event.nextDay = true;
      } else {
        event.nextDay = false;
      }
      newEventList.push(event);
    });
    return of(newEventList);
  }*/


  /*checkIfNextDay() {
    if (DateTime.Compare(this.currentDay, DateTime.Now.Today) !== 0) {
      this.currentDay = DateTime.Now.Today;
      //this.getEvents().toPromise().then();
    } else {

    }
  }*/


}



// ###### Interfaces ####### //

export interface ResourceObject {
  address: {
    city: string;
    countryOrRegion: string;
    postalCode: string;
    state: string;
    street: string;
  };
  rooms: Array<any>;
  avatar: string;
  building: string;
  connectorId: string;
  language: string;
  devices: Array<Room>;
  displayName: string;
  floorLabel: string;
  floorNumber: string;
  licenseNumber: string;
  organizationId: string;
  resourceAccount: string;
  viewLink: string;
  settings: RoomBoardSettings;
  _id: string;
  _t: string;
}


export interface RoomBoardSettings {
  gpdrPrivacy: boolean;
  transitionTime: number;
}

export interface Room {
  adress: {
    city: string;
    countryOrRegion: string;
    postalCode: string;
    state: string;
    street: string;
  };
  displayName: string;
  events: Array<any>;
  resourceAccount: string;
  _id: string;

}

export interface RoomBoardEvent {
  start: DateTime;
  end: DateTime;
  subject: string;
  nextSubject: string;
  isNext: boolean;
}

export interface EventObject {
  activeStatus: number;
  autoCancelTime: DateTime;
  checkedIn: boolean;
  end: any;
  duration: TimeSpan;
  id: string;
  isAllDay: boolean;
  members: string[];
  organizer: string;
  start: DateTime;
  subject: string;
  nextDay: boolean;
}
