import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ConditionalModalPage } from './conditional-modal/conditional-modal.page';
import { ErrorModalPage } from './error-modal/error-modal.page';
import { InputModalPage } from './input-modal/input-modal.page';
import { SuccessModalPage } from './success-modal/success-modal.page';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoLicensesModalComponent } from './no-licenses-modal/no-licenses-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NavigateModalComponent } from './navigate-modal/navigate-modal.component';

@NgModule({
  declarations: [NavigateModalComponent, ConditionalModalPage, ErrorModalPage, InputModalPage, SuccessModalPage, NoLicensesModalComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild()
  ],
  entryComponents: [NavigateModalComponent, ConditionalModalPage, ErrorModalPage, InputModalPage, SuccessModalPage, NoLicensesModalComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class ModalModule { }
