<ion-content fullscreen>
    <ion-button fill="clear" color="dark" class="modal-btn" (click)="close()"><ion-icon name="close"></ion-icon></ion-button>
    <ion-grid style="height: 100%">
      
      <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 100%">
        <ion-col size="8" class="ion-text-center"> 
            <ion-icon name="lock-closed-outline" color="success" style="font-size: 4.5em;"></ion-icon>
          <h2>{{'MODAL.TEXT.ENTER_PASSWORD' | translate }}</h2>
          <ion-item [class.ion-invalid]="error">
          <!-- <ion-label position="stacked">Password</ion-label> -->
          <ion-icon name="lock-closed-outline" color="medium"></ion-icon>
          <ion-input type="password" [formControl]="password" placeholder="{{'PLACEHOLDER.PASSWORD' | translate}}" ></ion-input>
          <ng-container *ngFor="let valid of errorList.pw">
            <ion-note *ngIf="submitted && password.hasError('required') || password.hasError('invalid')"></ion-note>
          </ng-container>
          </ion-item>
          <p *ngIf="error">{{'MODAL.TEXT.WRONG_PASSWORD' | translate}}</p>
          <ion-button type="submit" class="ion-padding" expand="block" color="dark" (click)="login()">{{'BTN.OK' | translate}}</ion-button>
          <ion-button class="ion-padding" fill="clear" color="medium" (click)="routingBack()">{{'BTN.BACK' | translate}}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>







