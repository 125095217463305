import { Injectable } from '@angular/core';
import { SuccessModalPage } from 'src/app/modals/success-modal/success-modal.page';
import { ModalController } from '@ionic/angular';
import { ErrorModalPage } from 'src/app/modals/error-modal/error-modal.page';
import { InputModalPage } from 'src/app/modals/input-modal/input-modal.page';
import { ConditionalModalPage } from 'src/app/modals/conditional-modal/conditional-modal.page';
import { NoLicensesModalComponent } from 'src/app/modals/no-licenses-modal/no-licenses-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { NavigateModalComponent } from 'src/app/modals/navigate-modal/navigate-modal.component';


export interface ModalOptions {
  component: any;
  componentProps: {
    defaultMessage: ModalMessages['defaultMessage']
    message: ModalMessages['message'];
  };
  backdropDismiss?: boolean;
  cssClass?: string;
}

export interface ModalMessages {
  defaultMessage?: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})

export class ModalService {

  constructor(private modalController: ModalController, private translate: TranslateService) {

  }

  async showModal(modelType: ModalOptions, message?: ModalMessages): Promise<any> {
    if (message !== undefined && message.message !== undefined) {
      modelType.componentProps.message = message.message;
    } else {
      const defaultMessage = this.translate.instant(modelType.componentProps.defaultMessage);
      modelType.componentProps.message = defaultMessage;
    }

    const modal = await this.modalController.create(modelType);

    await modal.present();
    return modal.onDidDismiss().then(data => {
      return data;
    });
  }


  async isModalOpen() {
    return await this.modalController.getTop();
  }

  async dismissModal() {
    return await this.modalController.dismiss().catch(_ => {});
  }
}



export class ModalType {
  /**
   *  Test
   */


  public static SUCCESS: ModalOptions = {
    component: SuccessModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.SUCCESS' ,
      message: ''
    }
  };

  public static ERROR: ModalOptions = {
    component: ErrorModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.ERROR',
      message: ''
    },
    backdropDismiss: true,
  };


  public static NOLICENSE: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.LICENSECHECK',
      message: ''
    },
    backdropDismiss: false,
  };

  public static NOPLACE: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.NOPLACE',
      message: ''
    },
    backdropDismiss: false,
  };

  public static NOCONNECTOR: ModalOptions = {
    component: NoLicensesModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.NOCONNECTOR',
      message: ''
    },
    backdropDismiss: false,
  };


  public static NOADDITIONALTITLES: ModalOptions = {
    component: ErrorModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.NOTITLES',
      message: ''
    },
    backdropDismiss: true,
  };

  public static INPUT: ModalOptions = {
    component: InputModalPage,
    componentProps: {
      defaultMessage: 'Please Check your License',
      message: ''
    },
    backdropDismiss: false,
  };



  public static CONDITIONAL: ModalOptions = {
    component: ConditionalModalPage,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.CONDITIONAL',
      message: ''
    },
  };

  public static NAVIGATE: ModalOptions = {
    component: NavigateModalComponent,
    componentProps: {
      defaultMessage: 'MODAL.DEFAULTMESSAGE.CONDITIONAL',
      message: ''
    },
  };

  /* public static CONDITIONAL: ModalOptions = {
     component: ConditionalModalPage
   };*/

}





