import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-conditional-modal',
  templateUrl: './conditional-modal.page.html',
  styleUrls: ['./conditional-modal.page.scss'],
})
export class ConditionalModalPage implements OnInit {
  @Input() message: string;

  constructor(private mdlCtrl: ModalController) { }

  ngOnInit() {

  }

  close(ev: boolean) {

    this.mdlCtrl.dismiss(ev);
  }

}
