import { environment } from './../../../environments/environment';
import { WebsocketService } from './websocket.service';
import { HttpInterfaceService } from './../http/http-interface.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of, throwError, Observable, TimeoutError, Subject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { catchError, timeout, switchMap, map, tap, retryWhen, delay, take, flatMap } from 'rxjs/operators';
import { HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SettingsInterface } from '../storage-service/storage-service.service';
import { Device } from '@ionic-native/device/ngx';
import { Router } from '@angular/router';
import { ResourceService } from '../resources/resource.service';
import { License } from './license.service';




@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = '';
  private apiUrl = '';
  private tokenUrl = '';
  private deviceUrl = '';
  private userEndpoint = '';
  private deviceEndpoint = '';
  private resourceEndpoint = ';';
  private licenseEndpoint = '';
  private autodiscoverEndpoint = '';
  private deviceHubEndpoint = '';
  public accessToken: string = null;
  private refreshToken: string = null;
  public softwareVersion = new BehaviorSubject<string>(undefined);
  public authenticationState = new BehaviorSubject(undefined);
  public loginClear = new Subject<boolean>();
  public relogin = false;
  public deviceId = new BehaviorSubject(undefined);
  public serialNumber = new BehaviorSubject<string>(undefined);


  userCode: string;
  public deviceCode: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(public http: HttpInterfaceService, private storage: Storage, private socket: WebsocketService, private device: Device, private resourceService: ResourceService) {
    this.baseUrl = environment.baseUrl;
    this.apiUrl = this.baseUrl + '/api';
    this.tokenUrl = this.baseUrl + '/connect/token';
    this.deviceUrl = this.baseUrl + '/connect/deviceauthorization';
    this.userEndpoint = this.apiUrl + '/Accounts';
    this.deviceEndpoint = this.apiUrl + '/Devices';
    this.licenseEndpoint = this.apiUrl + '/Licenses';
    this.resourceEndpoint = this.apiUrl + '/Resources';
    this.autodiscoverEndpoint = this.apiUrl + '/Autodiscover';
    this.deviceHubEndpoint = this.baseUrl + '/deviceHub';
  }


  getDeviceAuthCode() {

    const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
    const body = { client_id: 'device' };
    return this.http.post(this.deviceUrl, body, { headers }).pipe(map(x => {
      return x;
    }));
  }

  deviceAuth(deviceCode) {

    const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
    const body = { client_id: 'device', device_code: deviceCode, grant_type: 'urn:ietf:params:oauth:grant-type:device_code' };

    return this.http.post(this.tokenUrl, body, { headers }).pipe(
      map(r => {
        if (r.body.access_token && r.body.refresh_token) {
          this.setAccessToken(r.body.access_token);
          this.setRefreshToken(this.refreshToken = r.body.refresh_token);
          // this.authenticationState.next(true);
          return true;
        }
      })
    );
  }

  getDeviceCode() {
    return this.getDeviceAuthCode().pipe(
      take(1),
      map(deviceAuthObject => {
        if (deviceAuthObject) {
          this.userCode = deviceAuthObject.body.user_code;
          if (deviceAuthObject.body.device_code) {
            return deviceAuthObject.body;
          }
        }
      })
    );
  }

  tryGetLoginToken(deviceCode: string, interval?: number) {
    const retryInterval = interval * 1000;
    return this.deviceAuth(deviceCode).pipe(
      retryWhen(err => err.pipe(
        delay(retryInterval),
        tap(error => {

          if (error.error.error !== 'authorization_pending') {

            throw error;
          }
          if (error.error.error === 'expired_token') {
            return throwError(error);
          }
        })
      )),
      switchMap(res => {
        if (res) {
          return this.consentDevice().pipe();
        }
      })
    );
  }

  consentDevice() {
    return this.bindDeviceToResource(this.userCode).pipe(
      switchMap(x => {

        this.resourceService.resource.next(x);
        this.saveResourceId(x._id);

        return this.registerDevice(x.devices[0]._id).pipe();
      }
      ));
  }

  bindDeviceToResource(userCode: string) {

    return this.getAccessToken().pipe(
      switchMap(
        (token) => {
          if (token == null) {
            return this.refreshTokenRequest();
          }
          return of(token);
        }),
      switchMap(
        (token) => {
          const headers = {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json'
          };
          return this.http.get(this.apiUrl + '/resources/device/' + userCode, { headers }).pipe(
            timeout(30000),
            catchError((err): any => {
              if (err.status === 401) {
                return this.refreshTokenRequest().pipe(
                  switchMap((refreshToken) => {
                    if (refreshToken != null) {
                      return this.bindDeviceToResource(userCode);
                    }
                  }), catchError(this.handleError)
                );
              } else {
                return this.handleError(err);
              }
            })
          );
        }
      )
    );
  }

  registerDevice(deviceId: string) {

    return this.getUniqueDeviceId().pipe(
      switchMap((hwid) => {
        const data = { hardwareid: hwid };
        const headers = {
          Authorization: 'Bearer ' + this.accessToken,
          'Content-Type': 'application/json'
        };

        return this.getDeviceInfo().pipe(
          switchMap((device: Device) => {
            const deviceModel = {
              HardwareId: data.hardwareid,
              SerialNumber: device.serial,
              Platform: device.platform
            };
            this.storage.remove('serialNo');
            return this.http.put(this.resourceEndpoint + '/device/' + deviceId + '/activate', deviceModel, { headers }).pipe();
          })
        );
      }
      ),
      timeout(30000),
      catchError(this.handleError),
    );
  }

  getResource() {

    return this.getresourceId().pipe(
      switchMap(id => {

        return this.getAccessToken().pipe(
          switchMap(
            (token) => {
              if (token == null) {
                return this.refreshTokenRequest();
              }
              return of(token);
            }),
          switchMap(
            (token) => {
              const headers = {
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
              };
              return this.http.get(this.apiUrl + '/resources/roomboard/' + id, { headers }).pipe(
                timeout(30000),
                catchError((err): any => {
                  if (err.status === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap((refreshToken) => {

                        if (refreshToken != null) {
                          return this.getResource();
                        }
                      }), catchError(this.handleError)
                    );
                  } else {
                    return this.handleError(err);
                  }
                })
              );
            }
          )
        );
      })
    );
  }

  getresourceId() {
    if (this.resourceService.resource.getValue() === undefined) {
      return from(this.storage.get('resourceId')).pipe(
        switchMap(
          (id: string) => {
            return of(id);
          }
        )
      );
    }
    return of(this.resourceService.resource.getValue()._id);
  }

  createSocketConnection() {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (deviceid) => {
          return this.getAccessToken().pipe(
            switchMap(token => {
              return this.socket.createConnection(this.deviceHubEndpoint, token, deviceid).pipe(
                catchError(err => {
                  if (err !== undefined && err.statusCode === 401) {
                    return this.refreshTokenRequest().pipe(
                      switchMap(
                        (refreshtoken) => {
                          this.socket.socketStatus.next(false);
                          return of(true);
                        }
                      ));
                  }
                  setTimeout(() => this.socket.socketStatus.next(false), 10000);
                  return of(err);
                }));
            })
          );
        }
      )
    );
  }

  getAccessToken() {
    if (this.accessToken == null) {
      return from(this.storage.get('access_token')).pipe(
        switchMap(
          (token: string) => {
            if (token == null) {
              return this.refreshTokenRequest();
            }
            this.accessToken = token;
            return of(token);
          }
        )
      );
    }
    return of(this.accessToken);
  }

  getRefreshToken() {

    if (this.refreshToken == null) {
      return from(this.storage.get('refresh_token')).pipe(
        switchMap(
          token => {
            if (token == null) { return of(null); }
            this.refreshToken = token;
            return of(token);
          }
        )
      );
    } else {
      return of(this.refreshToken);
    }
  }

  refreshTokenRequest() {
    return this.getRefreshToken().pipe(
      switchMap(
        refreshToken => {
          if (refreshToken == null) {
            // return throwError('No Refresh Token');
          }
          const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
          // const body = `grant_type=refresh_token&refresh_token=${token}`;
          const data = { grant_type: 'refresh_token', refresh_token: refreshToken, client_id: 'device' };
          return this.http.post(this.tokenUrl, data, { headers }).pipe(
            timeout(30000),
            switchMap(
              (res) => { this.setAccessToken(res.body.access_token); this.authenticationState.next(true); return of(res.body.access_token); }
            ),
            catchError(err => {

              // this.setRefreshToken(null);
              // this.setAccessToken(null);
              this.authenticationState.next(false);
              this.loginClear.next(true);
              return throwError(of({ error: { status: 0 } }));
            })
          );
        }
      )
    );
  }

  getUniqueDeviceId() {

    //return of("bc81119d-f34c-0fec-e06a-34087639709r");
    if (this.deviceId.getValue() !== undefined) {

      return this.deviceId;
    } else {
      return from(this.storage.get('uuid')).pipe(
        switchMap(
          (uuid) => {
            if (uuid == null) {
              try {
                if (this.device.uuid !== null) {
                  uuid = this.device.uuid;

                } else {
                  uuid = Guid.raw();
                }
              } catch (error) {
                uuid = Guid.raw();
              }

              this.saveUuid(uuid);
            }

            this.deviceId.next(uuid);
            return of(uuid);
          }
        )
      );
    }
  }

  getDeviceInfo(): Observable<any> {
    let device;
    if (this.device.serial !== null) {
      this.saveSerial(this.device.serial);
      return of(this.device);
    }

    // Fallback Browser
    return of(device = {
      platform: 'Electron',
      serial: null,
    });

  }

  saveUuid(uuid: string) {
    this.storage.set('uuid', uuid);
  }

  saveSerial(serial: string) {
    this.storage.set('serialNo', serial);
  }

  setAccessToken(token: string) {
    this.storage.set('access_token', token);
    this.accessToken = token;
  }

  setRefreshToken(token: string) {
    this.storage.set('refresh_token', token);
    this.refreshToken = token;
  }

  saveResourceId(resourceId: string) {
    this.storage.set('resourceId', resourceId);
  }

  readDeviceLicense(): Observable<any> {

    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {

          return this.getAccessToken().pipe(
            switchMap(
              (token) => {

                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }
            ),
            switchMap(
              (token) => {
                this.accessToken = token;
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.get(this.licenseEndpoint + `/${hwid}` + '/Read', { headers }).pipe(
                  timeout(30000),
                  catchError(err => {
                    if (err.status === 401) {
                      return this.refreshTokenRequest().pipe(
                        switchMap(
                          (refreshtoken) => {

                            if (refreshtoken != null) {
                              return this.readDeviceLicense();
                            }
                          }
                        ), catchError(this.handleError)
                      );
                    } else if (err.status === 404) {
                      const license = {} as License;
                      license.status = 404;
                      return of(license);
                    } else {
                      return this.handleError(err);
                    }
                  })
                );
              }
            )
          );
        }
      )
    );
  }

  // ############### OLD IMPLEMENATATION #############################


  login(username: string, password: string) {
    const headers = { Accept: 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' };
    const data = { grant_type: 'password', username, password, client_id: 'api' };

    this.loginClear.next(false);

    this.relogin = true;

    return this.http.post(this.tokenUrl, data, { headers }).pipe(
      map(r => {

        if (r.body.access_token && r.body.refresh_token) {
          this.setAccessToken(r.body.access_token);
          this.setRefreshToken(this.refreshToken = r.body.refresh_token);
          this.authenticationState.next(true);
          return true;
        }
      }),
      timeout(30000),
      catchError(this.handleError)
    );
  }

  autodiscoverUrl(username: string, password: string) {
    const credentials = { data: (btoa(username + ':' + password)) };

    return this.getAccessToken().pipe(
      switchMap((token) => {
        this.accessToken = token;
        const headers = {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        };
        return this.http.post(this.autodiscoverEndpoint, credentials, { headers }).pipe(
          map(res => res.body),
          timeout(30000),
          catchError((err) => {
            if (err.status === 401) {
              return this.refreshTokenRequest().pipe(
                switchMap(
                  (refreshtoken) => {
                    if (refreshtoken != null) {
                      return this.autodiscoverUrl(username, password);
                    }
                  }
                ), catchError(this.handleError)
              );
            } else {
              return this.handleError(err);
            }
          })
        );
      })
    );
  }

  logout() {
    this.storage.remove('access_token').then(() => {
      this.authenticationState.next(false);
      this.storage.remove('refresh_token');
    });
  }

  resendActivation(email: string) {
    const headers = { 'Content-Type': 'application/json' };
    const data = { email };
    return this.http.post(this.userEndpoint + '/Pin', data, { headers }).pipe(timeout(30000), catchError(this.handleError));
  }

  register(firstname: string, lastname: string, company: string, email: string, password: string, passwordconfirm: string): Observable<HttpResponse<any>> {
    const headers = { 'Content-Type': 'application/json' };
    const data = { firstname, lastname, company, email, password, confirmPassword: passwordconfirm };
    return this.http.post(this.userEndpoint + '/Register', data, { headers }).pipe(timeout(30000), catchError(this.handleError));
  }

  activateAccount(email: string, pin: string) {
    const data = { email, pin };
    return this.http.get(this.baseUrl + `/ActivationLink?id=${data.email}&pin=${pin}`).pipe(timeout(30000), catchError(this.handleError));
  }

  resetPassword(email: string) {
    const headers = { 'Content-Type': 'application/json' };
    const data = { email };
    return this.http.post(this.userEndpoint + '/Reset', data, { headers }).pipe(timeout(30000), catchError(this.handleError));
  }



  private handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (error.status <= 0) {
        return throwError({ status: 0, error: { error: 'ConnectionError', error_description: 'Connection Problems' } });
      }

      if (error.status === 401) {
        return throwError({ status: error.status, error: { error: 'UnauthorizedError', error_description: 'Unauthorized' } });
      }

      if (error.error) {

        if (typeof error.error === 'string') {
          return throwError({ status: 0, error: { error: 'ConnectionError', error_description: 'Connection Problems' } });
        }
        return throwError({ status: error.status, error: error.error });
      }

      return throwError(error);
    }

    if (error instanceof TimeoutError) {
      return throwError({ status: 0, error: { error: 'TimeoutError', error_description: 'Timeout has occured' } });
    }

    return throwError({ status: 0, error: { error: 'ConnectionError', error_description: 'Connection Problems' } });

    /*if (error.error instanceof ErrorEvent) {
      return throwError(error.error);
    } else if (error.error !== undefined) {
      if ((error.error instanceof Object) === false) {
        try {
          error.error = JSON.parse(error.error);
        } catch {
          error.error = error.error;
        }
      }
      if (error.error.error_description !== undefined) {
        return throwError({ status: error.status, type: error.error.error, message: error.error.error_description });
      }
      return throwError({ status: error.status, message: error.error.message });
    } else if (error.status === 0) {
      return throwError({ status: error.status, message: 'No Connection' });
    } else {
      return throwError({ status: error.status, message: error.message });
    }*/
  }

  isAuthenticated() {


    /*this.storage.get(TOKEN_KEY).then((res) => {
      if (res) {
       this.authenticationState.next(true);
      } else  {
        this.authenticationState.next(false);
      }
    });*/

    return this.authenticationState.value;

  }


  /*Settings Requests*/

  getSettings(): Observable<any> {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {
          return this.getAccessToken().pipe(
            switchMap(
              (token) => {
                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }),
            switchMap(
              (token) => {
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.get<SettingsInterface>(this.deviceEndpoint + `/${hwid}` + '/Settings', { headers })
                  .pipe(
                    timeout(30000),
                    catchError((err): any => {
                      if (err.status === 401) {
                        return this.refreshTokenRequest().pipe(
                          switchMap((refreshToken) => {

                            if (refreshToken != null) {
                              return this.getSettings();
                            }
                          }), catchError(this.handleError)
                        );
                      } else {
                        return this.handleError(err);
                      }
                    })
                  );
              })
          );
        })
    );
  }


  postSettings(settings): Observable<any> {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {
          return this.getAccessToken().pipe(
            switchMap(
              (token) => {
                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }),
            switchMap(
              (token) => {
                const body = settings;
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.post(this.deviceEndpoint + `/${hwid}` + '/Settings', body, { headers })
                  .pipe(
                    timeout(30000),
                    catchError((err): any => {
                      if (err.status === 401) {
                        return this.refreshTokenRequest().pipe(
                          switchMap((refreshToken) => {

                            if (refreshToken != null) {
                              return this.postSettings(settings);
                            }
                          }), catchError(this.handleError)
                        );
                      } else {
                        return this.handleError(err);
                      }
                    })
                  );
              })
          );
        })
    );
  }


  /*Account Get*/

  getAccount(): Observable<any> {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {
          return this.getAccessToken().pipe(
            switchMap(
              (token) => {
                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }),
            switchMap(
              (token) => {
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.get<SettingsInterface>(this.deviceEndpoint + `/${hwid}` + '/Account', { headers })
                  .pipe(
                    timeout(30000),
                    catchError((err): any => {
                      if (err.status === 401) {
                        return this.refreshTokenRequest().pipe(
                          switchMap((refreshToken) => {

                            if (refreshToken != null) {
                              return this.getSettings();
                            }
                          }), catchError(this.handleError)
                        );
                      } else {
                        return this.handleError(err);
                      }
                    })
                  );
              })
          );
        })
    );
  }


  postAccount(settings): Observable<any> {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {
          return this.getAccessToken().pipe(
            switchMap(
              (token) => {
                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }),
            switchMap(
              (token) => {
                const body = settings;
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.post(this.deviceEndpoint + `/${hwid}` + '/Account', body, { headers })
                  .pipe(
                    timeout(30000),
                    catchError((err): any => {
                      if (err.status === 401) {
                        return this.refreshTokenRequest().pipe(
                          switchMap((refreshToken) => {

                            if (refreshToken != null) {
                              return this.postAccount(settings);
                            }
                          }), catchError(this.handleError)
                        );
                      } else {
                        return this.handleError(err);
                      }
                    })
                  );
              })
          );
        })
    );
  }


  public putDevice(device) {
    return this.getUniqueDeviceId().pipe(
      switchMap(
        (hwid) => {
          return this.getAccessToken().pipe(
            switchMap(
              (token) => {
                if (token == null) {
                  return this.refreshTokenRequest();
                }
                return of(token);
              }),
            switchMap(
              (token) => {
                const headers = {
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                };
                return this.http.put(this.resourceEndpoint + `/device/${hwid}`, device, { headers })
                  .pipe(
                    timeout(30000),
                    catchError((err): any => {
                      if (err.status === 401) {
                        return this.refreshTokenRequest().pipe(
                          switchMap((refreshToken) => {
                            if (refreshToken != null) {
                              return this.putDevice(device);
                            }
                          }), catchError(this.handleError)
                        );
                      } else {
                        return this.handleError(err);
                      }
                    })
                  );
              })
          );
        })
    );
  }
}

export class Guid {

  private constructor(guid: string) {
    if (!guid) { throw new TypeError('Invalid argument; `value` has no value.'); }

    this.value = Guid.EMPTY;

    if (guid && Guid.isGuid(guid)) {
      this.value = guid;
    }
  }

  public static validator = new RegExp('^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$', 'i');

  public static EMPTY = '00000000-0000-0000-0000-000000000000';

  private value: string;

  public static isGuid(guid: any) {
    const value: string = guid.toString();
    return guid && (guid instanceof Guid || Guid.validator.test(value));
  }

  public static create(): Guid {
    return new Guid([Guid.gen(2), Guid.gen(1), Guid.gen(1), Guid.gen(1), Guid.gen(3)].join('-'));
  }

  public static createEmpty(): Guid {
    return new Guid('emptyguid');
  }

  public static parse(guid: string): Guid {
    return new Guid(guid);
  }

  public static raw(): string {
    return [Guid.gen(2), Guid.gen(1), Guid.gen(1), Guid.gen(1), Guid.gen(3)].join('-');
  }

  private static gen(count: number) {
    let out = '';
    for (let i = 0; i < count; i++) {
      // tslint:disable-next-line:no-bitwise
      out += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return out;
  }

  public equals(other: Guid): boolean {
    // Comparing string `value` against provided `guid` will auto-call
    // toString on `guid` for comparison
    return Guid.isGuid(other) && this.value === other.toString();
  }

  public isEmpty(): boolean {
    return this.value === Guid.EMPTY;
  }

  public toString(): string {
    return this.value;
  }

  public toJSON(): any {
    return {
      value: this.value,
    };
  }
}

/*export class ApiService {

  authenticationState = new BehaviorSubject(false);

  constructor(private storage: Storage, private plt: Platform) {
      this.checkToken();
  }


  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    });
  }

  login() {
    return this.storage.set(TOKEN_KEY, '123456').then(() => {
      this.authenticationState.next(true);
    });
  }

  logout() {
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.authenticationState.next(false);
    });
  }

  isAuthenticated() {
    this.storage.get(TOKEN_KEY).then((res) => {
      if (res) {
       this.authenticationState.next(true);
      } else  {
        this.authenticationState.next(false);
      }
    });

    return this.authenticationState.value;

  }
}*/
