import { timeout, catchError, take, map } from 'rxjs/operators';
import { BehaviorSubject, of, Subject, throwError, from } from 'rxjs';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { EventObject, ResourceObject } from '../resources/resource.service';
import { DateTime } from 'ews-js-api-browser';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private connection: signalR.HubConnection;

  public licenseCallback = new Subject<boolean>();
  public settingsCallback = new Subject<boolean>();
  public accountCallback = new Subject<boolean>();
  public eventsCallback = new Subject<EventObject[]>();
  public resourceCallback = new Subject<ResourceObject>();
  public socketStatus = new Subject<boolean>();
  public connectionStatus = new BehaviorSubject<boolean>(undefined);
  public removeCallback = new Subject();
  public removeConnectorCallback = new Subject();
  private forceStop = false;
  constructor() {

  }

  createConnection(deviceHubEndpoint, accessToken, deviceId) {
    const url = deviceHubEndpoint;
    if (this.connection === undefined) {
      this.connection = new signalR.HubConnectionBuilder().withUrl(url, { accessTokenFactory: () => accessToken }).build();

      // this.connection.on('DashboardCall', data => {  });
      // this.connection.on('GetDeviceInfo', data => { this.getDeviceInfo(data); });
      // this.connection.on('ForceCallback', data => { this.forceCallback(data); });
      // this.connection.on('ConnectionClose', data => { this.connectionClose(data); });
      this.connection.on('LicenseCallback', data => { this.licenseCallback.next(true); });
      this.connection.on('AccountCallback', data => { this.accountCallback.next(true); });
      this.connection.on('EventsCallback', data => { this.eventsCallback.next(data); console.log('eventscallback', data); });
      this.connection.on('ResourceCallback', data => { this.resourceCallback.next(data); console.log('callback R:', data); });
      this.connection.on('RemoveCallback', data => { this.removeCallback.next(true); });
      this.connection.on('RemoveConnectorCallback', data => { this.removeConnectorCallback.next(true); });
      this.connection.onclose(() => {
        // const time = Math.floor(Math.random() * (60000 - 5000 + 1) + 5000);
        //
        this.connection = undefined;
        if (!this.forceStop) {
          setTimeout(() => {
            this.socketStatus.next(false);
            this.connectionStatus.next(false);
          }, 5000);
        } else {
          this.forceStop = false;
        }

      });

      return from(
        this.connection.start()
          .then(() => {

            return this.connection.invoke('HardwareAuthNew', deviceId);
          })
          .catch((err) => { throw err; })
          .then(() => {
            this.connectionStatus.next(true);
            return true;
          })
          .catch((error) => {
            this.connection.stop().catch(() => { });
            throw (error);
          })
      ).pipe(take(1), timeout(30000), catchError(err => {
        this.connection = undefined;
        this.connectionStatus.next(false);
        return throwError(err);
      }));
    } else {
      return of(true);
    }
  }

  hwidAuth(hwid) {

    this.connection.invoke('HardwareAuthNew', hwid).then().catch(err => {

    });
  }


  // this function call from Dashboard to get device data and validation
  getDeviceInfo(data) {


  }

  forceCallback(data) {

  }

  connectionClose() {
    if (this.connection !== undefined) {
      this.forceStop = true;
      this.connection.stop();
      this.connection = undefined;
    }
  }


  connectionCloseWithReconnect() {
    if (this.connection !== undefined) {
      this.connection.stop();
      this.connection = undefined;
    }
  }
}
