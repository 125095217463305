import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient as angularHttpModule, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, of, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HTTP } from '@ionic-native/http/ngx';


@Injectable({
  providedIn: 'root'
})
export class HttpInterfaceService {
  protected nativeIsAvailable: boolean | null = null;


  constructor(private nativeHttp: HTTP, private angularHttp: angularHttpModule, private platform: Platform) {
  }

  public isNativeHttpAvailable() {
    /*if (this.nativeIsAvailable === null) {
      this.nativeIsAvailable = this.platform.is('android') === true || this.platform.is('ios') === true;
    }*/
    return this.nativeIsAvailable;
  }

  public get<T>(url: string, options?: any): Observable<any> {
    if (this.isNativeHttpAvailable()) {
      return from(this.nativeHttp.get(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(options))).pipe(
        map((resp: any) => {
          try {
            resp.data = JSON.parse(resp.data);
          } catch { }
          return resp.data;
        }),
        catchError(err => {

          try {
            err.error = JSON.parse(err.error);
          } catch { }

          return throwError(new HttpErrorResponse(err));
        })
      );
    }
    return this.angularHttp.get<T>(url, options);
  }

  public post(url: string, body: any, options?: any): Observable<any> {
    if (this.isNativeHttpAvailable()) {

      switch (options.headers['Content-Type']) {
        case 'application/json': {
          this.nativeHttp.setDataSerializer('json');
          break;
        }

        case 'application/x-www-form-urlencoded': {
          this.nativeHttp.setDataSerializer('urlencoded');
          break;
        }

      }

      return from(this.nativeHttp.post(url, body, this.parseHeadersForNativeHttp(options))).pipe(
        map(resp => {
          try {
            resp.data = JSON.parse(resp.data);
          } catch { }
          return new HttpResponse({ body: resp.data, url: resp.url, status: resp.status, headers: resp.headers });
        }), catchError(err => {

          try {
            err.error = JSON.parse(err.error);
          } catch { }

          return throwError(new HttpErrorResponse(err));
        })
      );

    }

    if (options.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      body = Object.keys(body).map(key => key + '=' + body[key]).join('&');
    }
    return this.angularHttp.post(url, body, this.parseOptionsForAngularHttp(options)).pipe();
  }

  public put(url: string, body: any, options?: any): Observable<any> {
    if (this.isNativeHttpAvailable()) {

      switch (options.headers['Content-Type']) {
        case 'application/json': {
          this.nativeHttp.setDataSerializer('json');
          break;
        }

        case 'application/x-www-form-urlencoded': {
          this.nativeHttp.setDataSerializer('urlencoded');
          break;
        }
      }
      return from(this.nativeHttp.put(url, body, this.parseHeadersForNativeHttp(options))).pipe(
        map((res: any) => {
          return {
            json() {
              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          };
        }),
        catchError(err => { return throwError(err); })
      );
    }
    return this.angularHttp.put(url, body, this.parseOptionsForAngularHttp(options));
  }

  public delete(url: string, options?: HttpRequest<any>): Observable<any> {
    if (this.isNativeHttpAvailable()) {
      return from(this.nativeHttp.delete(url, this.parseParamsForNativeHttp(options), this.parseHeadersForNativeHttp(options))).pipe(
        map((res: any) => {
          return {
            json() {
              return JSON.parse(res.data);
            },
            text(ignoredEncodingHint) {
              return res.data.toString();
            },
            body: this.parseBodyFromNativeHttpResponse(res, options),
            headers: new Headers(res.headers)
          };
        })
      );
    }
    return this.angularHttp.delete(url, this.parseOptionsForAngularHttp(options));
  }

  private parseOptionsForAngularHttp(options) {
    let angularOptions: any = options;
    if (options instanceof HttpRequest) {
      angularOptions = {};
      angularOptions.headers = options !== undefined && options.headers !== undefined ? options.headers : {};
      angularOptions.params = options !== undefined && options.params !== undefined ? options.params : {};
    }
    if (angularOptions === undefined) {
      angularOptions = {};
      angularOptions.responseType = 'json';
    }
    if (angularOptions.responseType === undefined) {
      angularOptions.responseType = 'json';
    }
    if (angularOptions.observe === undefined) {
      angularOptions.observe = 'response';
    }
    return angularOptions;
  }

  private parseHeadersForNativeHttp(options) {
    return options !== undefined && options.headers !== undefined ? options.headers : {};
    /*let headers: Headers | {} | null = options !== undefined && options.headers !== undefined ? options.headers : {};
    if (headers instanceof Headers) {
      let newHeaders: any = {};
      headers.forEach(function (value, name) {
        newHeaders[name.toString()] = value.toString();
      });
      headers = newHeaders;
    }
    return headers;*/
  }

  private parseParamsForNativeHttp(options) {
    return options !== undefined && options.params !== undefined ? options.params : {};
  }

  private parseBodyFromNativeHttpResponse(res, options) {
    if (res.data) {
      if (options === undefined || options.responseType === undefined || options.responseType === 'json') {
        return JSON.parse(res.data);
      }
      return res.data;
    }
    return null;
  }

}
