import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { StorageService } from 'src/app/services/storage-service/storage-service.service';
import { Observable, of, from } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ValidateService, ValidationErrors } from 'src/app/services/validation/validate.service';


@Component({
  selector: 'app-input-modal',
  templateUrl: './input-modal.page.html',
  styleUrls: ['./input-modal.page.scss'],
})
export class InputModalPage implements OnInit {

  password = new FormControl('', Validators.required);
  checkPassword;
  submitted;
  hardwareId;
  error;
  errorList;
  constructor(private mdlCtrl: ModalController, private storageService: StorageService, private apiService: ApiService, private router: Router, private settingsService: StorageService, private validationService: ValidateService) {
    this.errorList = ValidationErrors.validMsg;
  }

  ngOnInit() {
    this.checkPassword = this.storageService.getSettingsPassword();
  }


  login() {
    this.submitted = true;
    if (this.password.valid) {
      if (this.password.value === this.checkPassword) {
        this.mdlCtrl.dismiss(true);
      } else if (this.password.value != this.checkPassword) {
        this.checkAgainstHadrwareId().subscribe(res => {
          if (res === this.password.value) {
            this.mdlCtrl.dismiss();
          } else {
            this.error = true;
            return false;
          }
        });
      } else {
        this.error = true;
        return false;
      }
    }
  }

  checkAgainstHadrwareId(): Observable<string> {
    let rescue: string;
    const a = this.apiService.getUniqueDeviceId().pipe(
      map((hwid: string) => {
        rescue = hwid.substring(hwid.length - 4);
        return rescue;
      }),
      take(1));
    return a;
  }

  routingBack() {
    this.router.navigateByUrl(this.settingsService.settings.getValue().theme).then(() => {
      this.mdlCtrl.dismiss();
    });

  }
  close() {
    this.routingBack();
  }

}
