import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-navigate-modal',
  templateUrl: './navigate-modal.component.html',
  styleUrls: ['./navigate-modal.component.scss'],
})
export class NavigateModalComponent implements OnInit {
  @Input() message: string;
  constructor(private mdlCtrl: ModalController) { }

  ngOnInit() {}

  close(ev: boolean) {

    this.mdlCtrl.dismiss(ev);
  }

}
